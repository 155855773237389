import React, { useEffect, useState } from 'react'
import { Container, Image, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import {toggleTheme} from "../features/theme/themeSlice"
import sound from "../assets/sounds/sound1.mp3"
import useCustomThemeDispatcher from '../utils/useCustomThemeDispatcher'
import { MoonStars, Sun } from '@phosphor-icons/react'

const NavBar = styled(Navbar)`

transition: 0.5s ease-in;
background: ${props => props.theme == "light" ? "#778899" : "#242529"};
/* background: dodgerblue; */
color: ${props => props.theme == "light" ? "black" : "white"};
overflow-x: hidden;

`
const NavBrand = styled(Navbar.Brand)`
transition: 0.5s;
scale: 140%;
margin-left: 7vw;
color: ${props => props.theme == "light" ? "white" : "white"};
&:hover{
  color: ${props => props.theme == "light" ? "white" : "white"};
}
`
const NavbarToggle = styled(Navbar.Toggle)`
border: none;
color: transparent;
background: transparent;
`
const NavLinki = styled(Nav.Link)`
margin-left: 55px;
transition: 0.5s;
font-weight: bold;
scale: 120%;
color: ${props => props.theme == "light" ? "white" : "white"} !important;
&:hover{
  color: ${props => props.theme == "light" ? "white" : "white"};
  
}
`

function Navbar_pr({refa}) {
  const theme = useSelector((state) => state.theme);
  const [expanded, setExpanded] = useState(false);
    const dispatchFunction = useCustomThemeDispatcher();
    const ClickHandler = () => {
        const audio = new Audio(sound);
        audio.play();
        dispatchFunction();
        
      };
  

  
  return (
   
    <NavBar ref={refa} className='navbar' theme={theme} expand="sm" collapseOnSelect={true}>
        <Container >

            <NavBrand href='#' className='navbrand' theme={theme}>Portfolio</NavBrand>
          
            <NavbarToggle aria-controls='basic-navbar-nav' className={`${expanded ? 'change' : ''}`} onClick={() => setExpanded(!expanded)}>
            <span className='bar1'></span>
            <span className='bar2'></span>
            <span className='bar3'></span>
            </NavbarToggle>

            <Navbar.Collapse id='basic-navbar-nav' >
                <Nav className='ms-auto linkiContainer'>
                  
                    <NavLinki theme={theme} href='#' className='' onClick={() => setExpanded(!expanded)}>Home</NavLinki>
                    <NavLinki theme={theme} href='https://www.linkedin.com/in/jishanarzoo' target="_blank" className='' onClick={() => setExpanded(!expanded)}>About</NavLinki>
                    <NavLinki theme={theme} href='mailto:hey@jishan.in'  className='' onClick={() => setExpanded(!expanded)}>Contact</NavLinki>
                    <NavLinki theme={theme}  className=''  onClick={() => ClickHandler()}>{theme == "light" ? (<><Sun size={25} style={{cursor: "pointer", color: "white"}}/></>) : (<><MoonStars size={25} style={{cursor: "pointer", color: "white"}}/></>)}</NavLinki>
                    
               

                </Nav>
            </Navbar.Collapse>


        </Container>
    </NavBar> 
    
  )
}

export default Navbar_pr
