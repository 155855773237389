import { createGlobalStyle } from "styled-components";

const GlobalStyles  = createGlobalStyle`


    body{
        
        

    }
    
`

export default GlobalStyles;