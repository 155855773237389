import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

// ChartJS.defaults.backgroundColor = '#';


ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

const options = {
 
  scales: {
      r: {
          angleLines: {
              display: false
          },
          suggestedMin: 20,
          suggestedMax: 100,
          
      },
      
  },
  
};

export const data = {
  labels: ['React.js', 'JavaScript', 'HTML  & CSS', 'Node & Express', 'MongoDB'],
  datasets: [
    {
      label: "Jishan",
      data: [90, 85, 79, 82, 83],
      backgroundColor: 'rgba(119, 136, 153, 0.3)',
      borderColor: '#778899',
      borderWidth: 2,
      pointHitRadius: 10,
      pointHoverRadius: 10,
      labelColor: "#ff0000",
      tension: 0.04,
    },
    {
      label: 'Average MERN Stack Developer',
      data: [74, 75, 71, 70, 70],
      backgroundColor: 'rgba(0, 191, 255, 0.3)',
      borderColor: 'rgba(0, 191, 255, 1)',
      borderWidth: 2,
      pointHitRadius: 10,
      pointHoverRadius: 10,
      tension: 0.04,
     
    },
  ],
};

export const data2 = {
    labels: ['React.js', 'JavaScript', 'HTML  & CSS', 'Node & Express', 'MongoDB'],
    datasets: [
      {
        label: "Jishan",
        data: [90, 85, 79, 82, 83],
        backgroundColor: 'rgba(119, 136, 153, 0.5)',
        borderColor: '#778899',
        borderWidth: 2,
        pointHitRadius: 10,
        pointHoverRadius: 10,
        labelColor: "#ff0000",
        tension: 0.04,
      },
      {
        label: 'Average MERN Stack Developer',
        data: [74, 75, 71, 70, 70],
        backgroundColor: 'rgba(0, 191, 255, 0.5)',
        borderColor: 'rgba(0, 191, 255, 1)',
        borderWidth: 2,
        pointHitRadius: 10,
        pointHoverRadius: 10,
        tension: 0.04,
      }
    ],
  };


  ChartJS.defaults.borderColor = 'rgba(119,136,157, 0.4)';
  ChartJS.defaults.color = "rgba(128, 128, 128, 1)";
  
 function SkillRadar() {
    const theme = useSelector((state) => state.theme)
    const [themeState, setThemeState] = useState(theme);
    
  return <Radar  options={options} data={theme == "light" ? data : data2} />;
}


export default SkillRadar;