import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";

export const ParentContainer = styled(Container)`
transition: 0.7s ease-in;
background-color: ${props => props.theme == "light" ? "white" : "black"};
position: relative;
z-index: 3;
box-shadow: ${props => props.theme == "light" ? "0 8px 32px 0 #1f26875e" : "0 8px 32px 0 #1f26875e"};

`


export const Container2 = styled(Container)`
position: sticky;
left:0;
bottom: 0;
z-index: 0;
`

export const Follower = styled(motion.div)`
width: 150px;
height: 150px;
border: 2px solid #ff0000;
background: none;
border-radius: 100%;
position: fixed;
pointer-events: none;
z-index: 999;
`