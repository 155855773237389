import React from 'react'
import "./App.css"
import Navbar from './components/Navbar'
import About from './components/About'
import Header from './components/Header'
import Skills from './components/Skills'
import Experience from './components/Experience'
import Projects from './components/Projects'
import Footer from './components/Footer'
import { Container2, ParentContainer, Follower } from './styled-components/StyledApp'

import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import GlobalStyles from './styled-components/GlobalStyles'
import FloatingNavBar from './components/FloatingNavBar'
import { useRef } from 'react'
import { useInView } from 'framer-motion'
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useFollowPointer } from './utils/UseFollowPointer'



function App() {
  const theme = useSelector((state) => state.theme);
  const navRef = useRef(null);
    const NavInView = useInView(navRef, {once: false});
  
  return (
    <>
    <SpeedInsights />
    <GlobalStyles />

    <ParentContainer fluid theme={theme}>
      <Row className='zeropadcol'>
        <Col xs={12} className="zeropadcol">
        <Navbar refa={navRef} />
        <FloatingNavBar NavInView={NavInView} />
        </Col>
      </Row>
      <Row>
        <Col className="zeropadcol">
        <Header />
        </Col>
      </Row>
      <Row>
        <Col  className="zeropadcol">
        <About />
        </Col>
      </Row>
      <Row>
        <Col className="zeropadcol">
          <Skills />
        </Col>
      </Row>
      <Row>
        <Col className="zeropadcol">
        <Experience />
        </Col>
      </Row>
      <Row>
        <Col className="zeropadcol">
        <Projects />
        </Col>
      </Row>
    
      
    </ParentContainer>

      <Container2 fluid theme={theme}>
      <Row>
        <Col className="zeropadcol">
        <Footer />
        </Col>
      </Row>
      </Container2>
     
      
     
      
      
    </>
  )
}

export default App
